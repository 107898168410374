@import (less) "normalize.css";
@import (less) "../node_modules/bootstrap/dist/css/bootstrap.css";

@import (less) "../js/vendor/royalslider/royalslider.css";
@import (less) "../js/vendor/royalslider/skins/default/rs-default.css";

@import (less) "../recaptcha/recaptcha.css";

@import (less) "savi-core.less";

@import (less) "variables.less";
@import (less) "menu-horizontal.less";
@import (less) "slider.less";
@import (less) "dropkick.less";
@import (less) "form.less";
@import (less) "thankyou.less";
@import (less) "home.less";
@import (less) "footer.less";

body {
  background-color: @cream-dark;
}
.bubble {
  width: 100%;
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 99;
  width: 200px;
  img {
    width: 100%;
  }
}
.position-relative {
  position: relative;
}
.page-wrapper {
  background-color: @cream-dark;
}

.space-above {
  display: block;
  padding-top: 10px;
}

.offset-left-padding {
  padding-left: 0;
}

.center-section {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0;
  right: 0;
}

.overflow {
  overflow: hidden;
}

.background-change-light {
  background-color: @cream-light;
  transition: background-color 500ms linear;
}

.background-change-dark {
  background-color: @cream-dark;
  transition: background-color 500ms linear;
}

.svg-no-underline {
  text-decoration: none;
  &:hover {
    border: none;
  }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .mobile-offset-padding {
    padding-left: 15px;
  }
  .bubble {
    width: 150px;
  }
}

// xs styles
@media (max-width: 767px) {
  .center-section {
    position: relative;
    transform: none;
    padding-top: @mobile-spacing;
  }

  .mobile-offset-padding {
    padding-left: 0;
  }

  // #waypoint_hero__up, #waypoint_location, #waypoint_interiors, #waypoint_team {
  //   height: 0;
  // }

  .space-above {
    padding-top: 5px;
  }
}
