@labelCol: @grey;

@inputCol: @navy;
@backgroundCol: transparent;

@formFontSize: 1em;

@submitCol: @cream-dark;
@submitBg: @navy;

@borderCol: @inputCol;

.border {
  margin-top: 10px;
}
.form-holder {
  width: 350px;
  float: left;
}
.error {
  color: red !important;
}
.form-title {
  width: 100%;
  color: @labelCol;
  height: 33px;
  padding: 7px 4px 0px 6px;
  margin-bottom: 2px;
}
.text-input,
.custom-select,
.custom-submit,
.custom-textarea {
  border-radius: 0px;
  appearance: none;
  height: 60px;
  width: 100%;
  border: 0;
  border-bottom: 1px solid @navy;
  font-size: @formFontSize;
  font-family: @font-family-base;
  font-weight: 300;
  letter-spacing: 0.05em;
  background-color: @backgroundCol;
  color: @inputCol;
  outline: none;
  text-align: center;
  .sans;
  text-transform: uppercase;
}

.custom-textarea {
  height: 100px;
}

.custom-select {
  padding-top: 18px;
  height: 60px;
  box-sizing: border-box;
  color: @navy;
  background: url("../img/core/arrow.png") 97% 50% no-repeat @backgroundCol;
}

.custom-submit {
  color: @cream-dark;
  background-color: @green;
  box-shadow: none;
  margin-top: 10px;
  height: 40px;
  width: 33%;
  font-size: @formFontSize;
  text-align: center;
  transition: 0.5s ease;
  display: block;
  margin: 0 auto;
  margin-top: @spacing;
  // border: none;
  border: 1px solid @green;
  &:hover {
     background-color: transparent;
     border: 1px solid @green;
     color: @green;
  }
}


.form-container {
  width: 80%;
  margin: 0 auto;
}
.form-container h4 {
  color: #fff;
  text-align: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 20px;
}
label {
  color: @labelCol;
  font-weight: bold;
}
::placeholder {
  color: @grey;
}
option {
  color: #000000 !important;
}

.customSelectInner {
    width: 100% !important;
}

.form-field-left-half, .form-field-right-half, .form-field-full {
  float: left;
  width: 50%;
  height: 50px;
}
.form-field-right-half {
  float: right;

}
.form-field-full {
  width: 100%;
}
.form-field{
  clear: both;
}

.enquire-form {
  position: fixed;
  transition: all 0.7s ease;
  overflow-y: scroll;
  top: -150vh;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background-color: @cream-dark;
  z-index: 400;
}

.enquire-heading {
  width: 40vw;
  text-align: center;
  margin: 0 auto;
  margin-top: calc(@spacing * 2);
  p {
    .serif;
    color: @navy;
    // font-size: 18px;
    font-size: 35px;
    // text-transform: uppercase;
  }
}

.enquire-active {
  top: 0;
}

.reg-form {
  width: 30%;
  margin: 0 auto;
  margin-top: calc(@spacing * 2);
}

.capital-logo-footer {
  margin: 0 auto;
  margin-top: @spacing;
  width: 120px;
}

.register-footer {
  margin: 30px auto 30px auto;
  width: 400px;
  text-align: center;
  p {
    .sans;
    font-size: 15px;
    text-transform: uppercase;
  }
}

.agent-wrapper {
  text-align: center;
}

.agent {
  .sans;
  font-size: 15px;
  text-transform: uppercase;
}

.top-form-wrapper {
  height: @top-menu-height;
  padding-top: @spacing;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
  .reg-form {
    width: 50%;
  }
  .enquire-heading {
    width: 80vw;
    p {
      font-size: @mobile-headline;
    }
  }
}

//Landscape tablet
@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : landscape) {
  .enquire-form {
    overflow: scroll;
  }
  .register-footer {
    position: relative;
    bottom: 0;
    margin-top: @spacing;
  }
}

// xs styles
@media (max-width: 767px) {
  .enquire-form {
    overflow: scroll;
  }
  .enquire-form {
    // height: calc(~"100vh - " @top-menu-height-mobile);
    // margin-top: calc(@top-menu-height-mobile);
  }

  .enquire-heading {
    margin-top: calc(@mobile-spacing * 2);

  }

  .reg-form {
    width: 80vw;
    margin-top: 30px;
  }

  .text-input,
  .custom-select,
  .custom-submit,
  .custom-textarea {
    height: 30px;
    font-size: 10px;
  }

  .custom-select {
    padding-top: 9px;
  }

  .custom-submit {
    width: 55%;
  }

  .register-footer {
    bottom: @mobile-spacing;
    width: 100%;
    p{
      font-size: @mobile-font;
    }
  }

  .agent {
    font-size: @mobile-font;
  }

  .top-form-wrapper {
    height: @top-menu-height-mobile;
    padding-top: @mobile-spacing;
  }
}
