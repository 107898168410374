.royalSlider {
    width: 100%;
    height: 641px !important;
    position: relative;
    direction: ltr;

    .rsBullets {
        position: relative;
        z-index: 35;
        left: 0;
        bottom: 25px;
        width: 100%;
        height: 20px;
        margin: 0 auto;
        // background: #000;
        background: transparent;
        text-align: center;
        line-height: 8px;
        overflow: hidden;
    }

    .rsBullet {
        width: 8px;
        height: 8px;
        display: inline-block;
        padding: 6px 11px 6px;



        span {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background: transparent;
            border: 1px solid @cream-dark;
        }
    }

    .rsNavSelected{
        span {
            background-color: @cream-dark !important;
        } 
    }

    .rsSlide {
        img {
            opacity: 0.45 !important;
            transition: all 0.3s ease-out;
            transform: scale(1);
        }
    }

    .rsActiveSlide {
        img {
            opacity: 1 !important;
            transform: scale(1);
        }
    }
}

#slider-embrace, #slider-maricott, #slider-marshallwhite, #slider-urban {
    height: 312px !important;
}



.rsDefault {
    &.rsHor {
        .rsArrowRight,
        .rsArrowLeft {
            height: 60px;
            background-color: transparent;
            background-position: top left;
            background-repeat: no-repeat;
        }
        .rsArrowLeft {
            .rsArrowIcn {
                //background-image: url('/img/sliders/rsSlider_arrowLeft.png');
                //left: 50px;
            }
        }
        .rsArrowRight {
            .rsArrowIcn {
                //background-image: url('/img/sliders/rsSlider_arrowRight.png');
                //right: 50px;
            }
        }
    }

    .rsOverflow {
        background-color: transparent;
    }

    .rsGCaption {
        background: transparent;
        right: 6px;
        left: auto;
        bottom: 5px;
        text-transform: uppercase;
        .sans;
        color: @cream-dark;
        font-size: 10px;
    }
}

.rsDefault,
.rsSlide {
    background-color: @cream-light;
}

.royalSlider,
.royalSlider .rsOverflow,
.royalSlider .rsSlide,
.royalSlider .rsVideoFrameHolder,
.royalSlider .rsThumbs {
    background: @cream-light;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
    
}

// xs styles
@media (max-width: 767px) {
    .royalSlider {
        width: 100%;
        height: 200px !important;
        .rsBullets {
            height: 12px;
            bottom: 22px;
        }
        .rsBullet {
            width: 8px;
            height: 8px;
            padding: 6px 11px 6px;
            span {
                width: 6px;
                height: 6px;
            }
        }
    }
    .rsDefault {
        .rsGCaption {
            font-size: 5px;
        }
    }

    #slider-embrace, #slider-maricott, #slider-marshallwhite, #slider-urban {
        height: 165px !important;
    }
}
