// FONTS
@font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
@font-family-serif:       Georgia, "Times New Roman", Times, serif;
@font-family-monospace:   Monaco, Menlo, Consolas, "Courier New", monospace;
@font-family-base:        @font-family-sans-serif;

// COLOURS
@core-color__default:     #000000;

@core-dropkick-bg:        #d9d9d2;
@core-dropkick-col:       #000000;

@navy       : #12121d;
@cream-light: #f5f0ec;
@cream-dark : #ebe2da;
@grey       : #948f8e;
@green      : #4b706e;
@rust       : #945532;

@top-menu-height: 100px;
@spacing: 30px;


@top-menu-height-mobile: 80px;
@mobile-spacing: 15px;
@mobile-heading: 19px;
@mobile-headline: 20px;
@mobile-font: 12px;
@mobile-font-xs: 8px;

.sans {
  font-family: moderat, Helvetica, Arial, Lucida, sans-serif;
}

.serif {
  font-family: MessinaModernRegular;
}

.col-green {
  color: @green;
}