.footer {
  padding-top: calc(@spacing * 2);
}

.address {
  padding-bottom: @spacing;
  text-align: center;
  p {
    margin: 0;
    color: @navy;
    .sans;
  }
}

.footer-logo-wrapper {
  width: 600px;
  margin: 0 auto;
  padding-bottom: @spacing;
}

.footer-embrace, .footer-maricott, .footer-capital, .footer-b-central {
  width: 100px;
  margin: 0 auto;
}

.footer-embrace {
  margin-top: 5px;
}

// .footer-maricott {

// }

.footer-capital {
  margin-top: 5px;
}

.bottom-enquire {
  position: relative;
  height: 100px;
  width: 100%;
}

.up-arrow {
  width: 20px;
  height: 20px;
  display: inline-block;
}

.backtotop {
  height: 20px;
  position: absolute;
  right: @spacing;
  top: @spacing;
  color: @navy;
  p{
    display: inline-block;
    padding-right: 10px;
    width: auto;
  }
}

.footer-menu-tagline {
  position: absolute;
	top: @spacing;
	left: @spacing;
}

.footer-enquire-button {
  width: 200px;
	height: 40px;
	background-color: @navy;
	position: absolute;
	top: @spacing;
  right: 0;
  left: 0;
  margin: 0 auto;
	text-align: center;
	a {
		position: relative;
		top: 25%;
		color: @cream-dark;
		text-decoration: none;
	}
	&:hover {
		background-color: transparent;
		border: 1px solid @navy;
		a {
			color: @navy;
		}
	}
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {

}

//Landscape tablet
@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) { 
  .footer-enquire-button {
    a {
      top: 10px;
    }
  }
}

// xs styles
@media (max-width: 767px) {
  .footer {
    padding-top: calc(@mobile-spacing * 2);
  }

  .footer-logo-wrapper {
    width: 80%;
    padding-bottom: @mobile-spacing;
  }

  .footer-enquire-button {
    width: 120px;
    height: 40px;
    top: 19px;
    a {
      position: relative;
      top: 0;
      color: @cream-dark;
      text-decoration: none;
      font-size: @mobile-font;
      margin-top: 12px;
      display: block;
    }
    &:hover {
      background-color: transparent;
      border: 1px solid @navy;
      a {
        color: @navy;
      }
    }
  }
  .footer-embrace, .footer-maricott, .footer-capital, .footer-b-central {
    width: 50px;
    margin: 0 auto;
  }

  .footer-embrace, .footer-capital {
    margin-top: 12px;
  }

  .footer-capital {
    margin-bottom:12px;
  }

  .address {
    padding-bottom: @mobile-spacing;
    p{
      font-size: @mobile-font;
    }
  }

  .footer-menu-tagline{
    bottom: @mobile-font;
    left: @mobile-font;
    p{
      margin: 0;
      font-size: @mobile-font-xs;
    }
  }

  .bottom-enquire {
    height: 80px;
  }

  .backtotop {
    right: @mobile-spacing;
    top: @mobile-spacing;
    p{
      font-size: @mobile-font-xs;
      margin: 0;
      padding: 0;
      margin-top: 20px;
      padding-bottom: 5px;
      text-align: center;
    }
  }

  .up-arrow {
    display: block;
    width: 10px;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}



