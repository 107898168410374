.hero-wrapper {
  //height: 100vh;
  // height: calc(~"100vh - "  @top-menu-height);
  background-image: url('../img/bg-hero.png');
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: calc(@top-menu-height);
  // margin-bottom: 20px;
}
.hero-heading {
  padding-top: 5vh;
  margin-bottom: 5vh;
}
.hero-info {
  font-size: 35px;
  color: @navy;
  line-height: normal;
  .serif;
}
.interiors-wrapper, .location-wrapper, .team-wrapper {
  position: relative;
  //height: calc(~"100vh - "  @top-menu-height);
  padding-bottom: calc(@spacing * 2);
}

.interiors-wrapper, .team-wrapper, .form-wrapper {
  background-color: @cream-light;
}
.form-wrapper {
  padding-top: 5%;
  padding-bottom: 5%;
}

.display-address {
  color: @navy;
  font-size: 14px;
  text-transform: uppercase;
  .sans;
}

.headline {
  color: @navy;
  font-size: 22px;
  .serif;
  line-height: normal;
}

.heading {
  color: @navy;
  .serif;
  font-size: 24px;
  // text-transform: uppercase;
  text-align: center;
  margin-top: calc(@spacing * 2);
  margin-bottom: calc(@spacing * 2);
}

.copy {
  .sans;
  color: @navy;
  font-size: 15px;
}

.team-top, .team-bottom {
  height: 50%;
}

.team-top {
  margin-bottom: @spacing;
}

.team-maricott-logo, .team-embrace-logo, .team-marshallwhite-logo {
  padding-top: @spacing;
  width: 120px;
}

.team-copy {
  p {
    color: @navy;
    .sans;
    font-size: 13px;
  }
  a {
    text-decoration: none;
    color: @navy;
    .sans;
    &:hover {
      border-bottom: 1px solid @navy;
    }
  }
}

.waypoint-height {
  height: 1px;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {

}

// xs styles
@media (max-width: 767px) {
  .hero-wrapper {
    height: auto;
    background-image: url('../img/bg-hero-mob.png');
    margin-top: calc(@top-menu-height-mobile);
  }
  .hero-heading {
    padding-top: 1vh;
  }
  .interiors-wrapper, .location-wrapper, .team-wrapper {
    padding-bottom: calc(@mobile-spacing * 2);
  }

  .heading {
    margin-top: calc(@mobile-spacing * 2);
    margin-bottom: calc(@mobile-spacing * 2);
  }

  .team-top {
    margin-bottom: 80px;
  }

  .team-maricott-logo, .team-embrace-logo, .team-marshallwhite-logo {
    padding-top: @mobile-spacing;
  }

  .hero-info {
    font-size: @mobile-headline;
    margin-top: calc(@mobile-spacing * 2);
  }

  .display-address {
    font-size: @mobile-font;
    margin-top: @mobile-spacing;
    margin-bottom: calc(@mobile-spacing * 2);
  }

  .heading {
    font-size: @mobile-heading;
    margin-top: calc(@mobile-spacing * 2);
    margin-bottom: calc(@mobile-spacing * 2);
  }

  .headline {
    font-size: @mobile-headline;
    margin-top: calc(@mobile-spacing * 2);
  }

  .copy {
    font-size: @mobile-font;
    margin-top: @mobile-spacing;
  }

  .team-top, .team-bottom {
    height: auto;
  }

  .team-copy {
    p {
      font-size: @mobile-font;
    }
    a{
      margin-top: 15px;
      margin-bottom: 15px;
      display: block;
      font-size: @mobile-font;
    }
  }

  .team-maricott-logo, .team-embrace-logo, .team-marshallwhite-logo {
    padding-top: @mobile-spacing;
    width: 95px;
  }

  .slider-hero-mobile {
    height: 400px !important;
  }
}