.thankyou {
    .hide-tracker {
        height: 0 !important;
        width: 0 !important;
        line-height: 0 !important;
        font-size: 0 !important;
        margin-top: -13px;
        float: left;
    }
}

.thankyou-wrapper {
    height: calc(~"100vh - "  @top-menu-height);
    width: 100%;
    text-align: center;
    margin-top: calc(@top-menu-height);
}

.thankyou-msg {
    position: relative;
    top: calc(~"50% - " 133px);
    transform: translateY(-50%);
    p{
        font-size: 20px;
        .serif;
        color: @navy;
    }
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {

}

// xs styles
@media (max-width: 767px) {
    .thankyou-wrapper {
        height: calc(~"100vh - "  @top-menu-height-mobile);
        margin-top: calc(@top-menu-height-mobile);
        width: 80%;
        margin: 0 auto;
    }

    .thankyou-msg {
        top: calc(~"65% - " 133px);
        p{
            font-size: 15px;
        }
    }
}