.menu-horizontal {
	list-style: none;
	padding: 0;
	margin: 25px 0;
	text-align: center;
	li {
		display: inline-block;
		list-style: none;
		padding: 0;
		margin-right: 30px;
		text-transform: uppercase;
		a {
			transition: 0.5s ease;
			text-decoration: none;
			.sans;
			color: @navy;
			&:hover {
				transition: 0.5s ease;
		 		text-decoration: none;
			}
		}
		&:last-child {
			margin-right: 0px;
		}
	}
}

.logo-wrapper {
	width: 300px;
	margin: 0 auto;
}

.menu-wrapper {
	position: fixed;
	top: 0;
	height: @top-menu-height;
	width: 100%;
	padding-top: @spacing;
	background-color: @cream-dark;
	z-index: 300;
}

.menu-link-active {
	border-bottom: 1px solid @navy;
}

.menu-tagline {
	position: absolute;
	top: @spacing;
	left: @spacing;
}

.enquire-button {
	// width: 200px;
	// height: 40px;
	a {
    background-color: @green;
    position: absolute;
    top: @spacing;
    right: @spacing;
    text-align: center;
		// position: relative;
		// top: 25%;
		color: @cream-dark;
		text-decoration: none;
    padding: 10px 35px;
    font-size: 1em;
    text-transform: uppercase;
	}
	&:hover {
    a {
      background-color: transparent;
      border: 1px solid @green;
			color: @green;
		}
	}
}

.menu-logo {
	height: 44px;
	display: block;
}

// md styles
@media (max-width: 1199px) {

}

// sm styles
@media (max-width: 991px) {
	.enquire-button {
    width: 150px;
    height: 35px;
	}
}

// xs styles
@media (max-width: 767px) {
	.logo-wrapper {
		width: 100px;
		margin-bottom: @mobile-spacing;
    margin-right: 0;
    margin-left: 15px;
    margin-top: 10px;
	}

	.menu-logo {
		height: 22px;
	}

	.enquire-button {
		top: @mobile-spacing;
    right: @mobile-spacing;
    width: 75px;
    height: 20px;
    font-size: @mobile-font-xs;
	}

	.menu-tagline {
		top: @mobile-spacing;
		left: @mobile-spacing;
		font-size: @mobile-font-xs;
	}

	.menu-wrapper {
		padding-top: @mobile-spacing;
		height: @top-menu-height-mobile;
	}

	.hamburger {
		width: 20px;
		margin: 0 auto;
	}

	.menu-mobile {
		background-color: @cream-dark;
		padding: 20px;
		text-align: center;
    position: fixed;
    width: 100%;
		top: -300px;
		transition: all 0.7s ease;
		z-index: 200;
		ul {
			padding: 0;
		}
		li {
			text-transform: uppercase;
			list-style: none;
			padding: 5px 0;
		}
		a {
			color: @navy;
		}
		.close-menu {
			font-size: 20px;
		}
	}

	.menu-link-active{
		border: none;
	}

	.menu-mobile-active {
		top: 50px;
	}
}

